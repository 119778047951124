<template>
  <div class="admin-guidance-create-playbook">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Add Playbook</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- No playbooks to link -->
    <div v-if="!playbooksToLink.length">
        <a-alert type="info" message="No playbooks left to add.">
        </a-alert>
        <a-button style="margin-top: 20px;" type="primary" icon="plus" @click.prevent="addNewPlaybook">
            Click here to add a new playbook
        </a-button>
    </div>
    <!-- / No playbooks to link -->

    <!-- Has playbooks to link -->
    <div v-if="playbooksToLink.length">
      <!-- Form -->
      <div v-if="!isLoading" class="form">
        <a-form-item label="Playbook">
          <a-select
            v-model="playbookLocal.checklistId"
            style="width: 100%"
            size="large"
          >
            <a-select-option
              v-for="playbook in playbooksToLink"
              :key="playbook.id"
              :value="playbook.id"
              >{{ playbook.displayName }}</a-select-option
            >
          </a-select>
        </a-form-item>

        <a-form-item label="Visibility">
          <a-radio-group v-model="playbookLocal.scope">
            <a-radio :value="1">Shown to organisation</a-radio>
            <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
            <a-radio :value="3">Hidden from lists</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="Display Order">
          <a-radio-group v-model="playbookLocal.rank">
            <a-radio v-for="(rank, i) in ranks" :key="i" :value="rank.rank">{{
              rank.label
            }}</a-radio>
          </a-radio-group>
        </a-form-item>
      </div>
      <!-- / Form -->

      <a-button
        @click.prevent="save"
        class="btn-rounded save-button"
        size="large"
        type="primary"
        >Save</a-button
      >
    </div>
    <!-- / Has playbooks to link -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import checklists from "../../../../api/checklists";
import guidanceHelpers from "../../../../helpers/guidance";
const _ = require("lodash");

export default {
  components: { LoadingScreen },

  data() {
    return {
      playbookLocal: {
        checklistId: null,
        scope: 1,
        rank: 0,
      },

      isSaving: false,
    };
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    if (!this.playbookLocal.checklistId && this.playbooksToLink.length) {
      this.playbookLocal.checklistId = _.first(this.playbooksToLink).id;
    }
  },

  methods: {
    addNewPlaybook() {
        // if (window.activeProfile == 'testing') {
        //     window.open('https://portal.testing.teamsos.co.uk/App/Settings/Playbooks');
        // } else {
        //     window.open('https://portal.teamsos.co.uk/App/Settings/Playbooks');
        // }
        this.$router.push('/admin/settings/playbooks/create');
    },

    goBack() {
      this.$router.push("/admin/guidance/playbooks");
    },

    getValidationErrors() {
      let errors = [];
      if (!this.playbookLocal.checklistId) {
        errors.push("Please select a playbook");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      let params = {
        ...this.playbookLocal,
        ownerId: this.selectedOrganisation.id,
      };

      checklists
        .addChecklistContent(vm.tenantId, params)
        .then(() => {
          vm.$message.success("Playbook added successfully");
          vm.$router.push("/admin/guidance/playbooks");
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    ...mapGetters("adminPlaybooks", {
      playbooksToLink: "playbooksToLink",
    }),

    ranks() {
      return guidanceHelpers.listRankOptions();
    },

    selectedPlaybook() {
      if (!this.playbookLocal.checklistId) {
        return null;
      }
      return _.find(this.playbooksToLink, {
        id: this.playbookLocal.checklistId,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.admin-guidance-create-playbook {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .top-row {
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>